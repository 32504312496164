.main_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
    position: relative;
    background: #1E202C;
    border-radius: 16px;
    height: auto;
    justify-content: center;
    align-items: center;
    padding: 32px 24px;
    margin: 30px 0 0 0;
    width: 55%;
    text-align: center;
}

.main_box {
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.box {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: space-between;
    width: 100%;
}
.name {
    margin-bottom: 24px !important;
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }
    .box {
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        line-break: anywhere;
    }
}
