.container {
    background-color: #1E202C;
    border-radius: 16px;
    height: 370px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}


.box {
    width: 90%;  
}

.row_box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 25px;
}

.currentAmountBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
    opacity: 70%;
}

.buttons_box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 52px;
}

input{
    opacity: 1 !important;
}

@media (max-width: 480px) {
    .box {
        width: 100%;  
    }
    .row_box {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 20px;
        text-align: left;
        width: 100%;
    }
    .currentAmountBlock {
        display: flex;
        flex-direction: row;
        text-align: left;
        justify-content: flex-start;
        gap: 15px;
    }

    .mainText {
        font-size: 15px;
    }
    
}
