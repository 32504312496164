.container {
    width: 100%;
    max-width: 1616px;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding: 0 15px;
    max-height: 184px;
}

.block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #1E202C;
    max-width: 476px;
    width: 100%;
    padding: 36px 32px;
    border-radius: 16px;
    gap: 15px;
}

.amountBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    background: #1E202C;
    max-width: 476px;
    width: 100%;
    padding: 24px 31px;
    border-radius: 16px;
}

.headingWithTooltip {
    display: flex;
    flex-direction: row;
}

.buttonsBlock {
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: space-between;
    width: 90%;
}

.orderBlock {
    display: flex;
    flex-direction: column;
    max-width: 280px;
    width: 100%;
}


.mainContainer {
    display: flex;
    justify-content: center;
    margin-top: 48px;
}

@media (max-width: 768px) {
    .container {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    .mainContainer {
        margin-top: 20px;
        min-height: 520px
    }

    .block {
        padding: 15px 32px;
        height: 110px;
    }

    .amountBlock {
        padding: 24px 15px;
    }
    
}

@media (max-width: 480px) {
    .mainContainer {
        margin-top: 20px;
        min-height: 500px
    }

    .container {
        gap: 20px;
    }
}