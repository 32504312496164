@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

html,
body,
#root {
    height: 100%;
    font-family: Roboto, sans-serif !important;
    margin: 0;
    background: #121421;
}

input:-webkit-autofill, input:-webkit-autofill:focus {
    -webkit-transition: background-color 600000s 0s, color 600000s 0s;
    transition: background-color 600000s 0s, color 600000s 0s;
    background: transparent !important;
}

.cursor {
    cursor: pointer;
}

.MuiTableContainer-root::-webkit-scrollbar {
    width: 8px;
    height: 0px;
}

/* Track */
.MuiTableContainer-root::-webkit-scrollbar-track {
    background: transparent;
}

.MuiTableContainer-root::-webkit-scrollbar-corner {
    background: transparent;
}

/* Handle */
.MuiTableContainer-root::-webkit-scrollbar-thumb {
    background: #292B37;
    border-radius: 8px;
}

/* Handle on hover */
.MuiTableContainer-root::-webkit-scrollbar-thumb:hover {
    background: #292B37;
}
