.table_container {
    position: relative;
    height: auto;
    max-height: 624px;
    max-width: 1616px;
    background: #1E202C !important;
    border-radius: 16px !important;
    overflow: auto;
    margin: 32px 10px;
    width: unset !important;
}
.table_container td {
    background: #1E202C;
    color: #ffffff !important;
    border-style: solid;
    border-width: 0px 1px 1px 0px;
    border-color: #262834;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
}

.table_container tbody tr:nth-last-child(1) td {
    border-width: 0 1px 0 0
}

.table_container tbody tr td:nth-last-child(1) {
    /*border-width: 1px 0 0 0*/
    border-right: 0;
}

.table_container th {
    background: #1E202C;
    color: #ffffff !important;
    border-color: #262834;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
}

.buyColor {
    color: #05FFA5;
}

.sellColor {
    color: #D62424;  
}

.manualSource {
    color: #ffffff;
}

.historyContainer {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    padding: 0 15px;
    gap: 20px;
}

.empty_container {
    display: flex;
    justify-content: center;
    padding: 50px;
    max-height: 624px;
    max-width: 100%;
}

.mainContainer {
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (max-width: 480px) {
    .historyContainer {
        flex-direction: column;
        align-items: center;
    }

    .historyContainer button {
        width: 100% !important;
    }
}