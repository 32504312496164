.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 442px;
    width: 100%;
    background: #1E202C;
    border: transparent;
    border-radius: 16px;

}

.formLabel {
    text-align: center;
    font-size: 14px;
    margin-bottom: 8px;
}

@media (max-width: 480px) {
    .firstInput {
        padding: 0 10px;
    }

    .secondInput {
        padding: 0 10px;
    }

    .button {
        padding: 0 10px;
    }

    .formContainer {
        border-radius: 0;
    }
}