.qrContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1E202C;
    border-radius: 16px;
    padding: 56px;
}

.scanBox {
    position: relative;
}

.scanBox video {
    border-radius: 16px;
}

.scanBox div {
    border-radius: 16px;
}

.close {
    position: absolute;
    top: -30px;
    right: -30px;
    z-index: 2;
    color: #667080;
}

.qrText {
    position: absolute;
    bottom: -12%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
}

.switch {
    position: absolute!important;
    bottom: -10%;
    right: -10%;
}

@media (max-width: 480px) {
    .qrContainer {
        padding: 32px 28px 48px 28px;
        border-radius: 0;
    }

    .close {
        top: -24px;
        right: -19px;
    }

    .qrText {
        bottom: -17%;
    }

    .switch {
        bottom: -14%;
        right: -7%;
    }

}