.container {
    background-color: #1E202C;
    border-radius: 16px;
    height: 300px;
    width: 90%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.box {
    width: 90%;  
}

.buttons_box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
}