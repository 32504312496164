.container {
    display: flex;
    flex-direction: column;
    position: relative;
    background: #1E202C;
    border-radius: 16px;
    height: auto;
    justify-content: space-between;
    align-items: center;
    padding: 32px 24px;
    margin: 30px 0 0 0;
    width: 55%;
}
.box {
    text-align: center;
    justify-content: space-between;
}
.pay_currency {
    font-weight: 600;
    color: #F7C22B;
}

.recieve_currency {
    font-weight: 600;
}